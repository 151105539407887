import React, { useState, useEffect } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Button,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  InputGroup,
  InputRightElement,
  Tabs,
  TabList,
  Modal,
  useDisclosure,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  useToast,
  AspectRatio,
  FormControl,
  FormLabel,
  List,
  ListItem,
} from '@chakra-ui/react';
// import { SearchIcon } from 'lucide-react';
import DashboardHeader from '../../Header/DashboardHeader';
import { SearchIcon } from '@chakra-ui/icons';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config';

// Mock data - replace with actual API call
const mockEntries = [
  {
    id: 1,
    name: 'Abdul Maroof',
    email: 'abdulmaroof@gmail.com',
    phone: '+92 525 656532',
    date: '12 Sep 2024 4:08 PM',
  },
  {
    id: 2,
    name: 'Abdul Maroof',
    email: 'abdulmaroof@gmail.com',
    phone: '+92 525 656532',
    date: '12 Sep 2024 4:08 PM',
  },
  {
    id: 3,
    name: 'Abdul Maroof',
    email: 'abdulmaroof@gmail.com',
    phone: '+92 525 656532',
    date: '12 Sep 2024 4:08 PM',
  },
  {
    id: 4,
    name: 'Abdul Maroof',
    email: 'abdulmaroof@gmail.com',
    phone: '+92 525 656532',
    date: '12 Sep 2024 4:08 PM',
  },
  {
    id: 5,
    name: 'Abdul Maroof',
    email: 'abdulmaroof@gmail.com',
    phone: '+92 525 656532',
    date: '12 Sep 2024 4:08 PM',
  },
  {
    id: 6,
    name: 'Abdul Maroof',
    email: 'abdulmaroof@gmail.com',
    phone: '+92 525 656532',
    date: '12 Sep 2024 4:08 PM',
  },
];

export default function ContestWrap() {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isWinnerOpen,
    onOpen: onWinnerOpen,
    onClose: onWinnerClose,
  } = useDisclosure();

  const toast = useToast();
  const user = useSelector(state => state?.value);
  const [entries, setEntries] = useState(mockEntries);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [winner, setWinner] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const selectWinner = () => {
    const randomIndex = Math.floor(Math.random() * entries.length);
    setWinner(entries[randomIndex]);
  };

  const filteredEntries = entries.filter(entry =>
    entry?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getContestEntries = async () => {
    try {
      let res = await GET('/contest', {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res.status === 200) {
        setEntries(res.data);
      } else {
        toast({
          description: res.data.message,
          status: 'error',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function openVideo(value) {
    onOpen();
    setSelectedVideo(value);
  }

  async function handleSubmit(event) {
    setisLoading(true);
    event.preventDefault();
    const formData = new FormData(event.target);
    // Check if values are filled
    if (!formData.get('name') || !formData.get('email')) {
      toast({
        description: 'Please fill the fields',
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
      return;
    }
    try {
      let res = await PUT('/contest/winner', formData, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res.status === 200) {
        toast({
          description: 'Winner selected successfully',
          status: 'success',
          position: 'top-right',
          duration: 2500,
        });
        setisLoading(false);
        getWinner();
        onWinnerClose();
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500,
        });
        setisLoading(false);
      }
    } catch (error) {
      console.error(error);
      setisLoading(false);
      toast({
        description: 'Failed to Select a Winner',
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
  }

  // Handle the input change for email and filter suggestions
  const handleEmailChange = e => {
    const value = e.target.value;
    setEmailInput(value);

    // Filter email suggestions based on user input
    if (value.length > 0) {
      const filtered = participants.filter(email =>
        email.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmails(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = email => {
    setEmailInput(email); // Set the input to the selected email
    setShowSuggestions(false); // Hide the suggestion box after selecting
  };

  const getWinner = async () => {
    try {
      let res = await GET('/contest/winner', {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res.status === 200) {
        setWinner(res.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        description: 'Failed to Get Winner',
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
  };

  const getEmails = async () => {
    try {
      let res = await GET('/contest/contestant', {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res.status === 200) {
        console.log(res.data);
        setParticipants(res.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        description: 'Failed to Get Emails',
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getContestEntries();
      getWinner();
      getEmails();
    }
  }, [user]);

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Heading fontSize="24px" fontWeight="600">
                Contest List
              </Heading>
              <Text mt={2}>You've {entries.length} Entries</Text>
            </Box>
            {/* <Button 
                            onClick={selectWinner}
                            colorScheme="green"
                        >
                            Select Winner
                        </Button> */}
          </Flex>
          <Button
            onClick={onWinnerOpen}
            w="200px"
            transform={'Skew(-8deg)'}
            bg="primaryGreen.100"
            color="primaryYellow.100"
            _hover={{ bg: 'primaryYellow.100', color: 'white' }}
          >
            Select Winner
          </Button>
        </Stack>

        <Tabs variant="enclosed">
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Flex justifyContent="space-between" alignItems="center">
                <Tab
                  bg="white"
                  color="black"
                  w="200px"
                  transform={'Skew(-8deg)'}
                  _selected={{
                    bg: 'primaryGreen.100',
                    color: 'primaryYellow.100',
                  }}
                >
                  All Entries
                </Tab>
                <Tab
                  bg="white"
                  w="200px"
                  color="black"
                  transform={'Skew(-8deg)'}
                  _selected={{
                    bg: 'primaryGreen.100',
                    color: 'primaryYellow.100',
                  }}
                >
                  Winner
                </Tab>
              </Flex>
            </Box>
            <InputGroup maxWidth="500px">
              <Input
                placeholder="Search for this..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <InputRightElement>
                <SearchIcon />
              </InputRightElement>
            </InputGroup>
          </Flex>
          <TabPanels>
            <TabPanel>
              {/* <InputGroup mb={4}>
                                <Input 
                                    placeholder="Search for this..." 
                                    value={searchTerm} 
                                    onChange={handleSearch}
                                />
                                <InputRightElement>
                                    <SearchIcon />
                                </InputRightElement>
                            </InputGroup> */}
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Entry</Th>
                    <Th>Email</Th>
                    <Th>Phone Number</Th>
                    <Th>Date & Time</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredEntries.map((entry, index) => (
                    <Tr key={entry.id}>
                      <Td>
                        <Text fontWeight="bold">Entry {index + 1}</Text>
                        <Text>{entry.name}</Text>
                      </Td>
                      <Td>{entry.email}</Td>
                      <Td>{entry.phone}</Td>
                      <Td fontWeight="500">
                        {new Date(
                          String(entry.createdAt).split('T')[0]
                        ).toLocaleDateString('en-US', options)}
                      </Td>
                      <Td>
                        <Button
                          onClick={() => openVideo(entry?.videoUrl)}
                          w="120px"
                          transform={'Skew(-8deg)'}
                          bg="primaryGreen.100"
                          color="primaryYellow.100"
                          _hover={{ bg: 'primaryYellow.100', color: 'white' }}
                        >
                          View Video
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              {winner ? (
                <Box>
                  <Heading size="md" mb={4}>
                    Winner
                  </Heading>
                  <Text>
                    <strong>Name:</strong> {winner.name}
                  </Text>
                  <Text>
                    <strong>Email:</strong> {winner.email}
                  </Text>
                  <Text>
                    <strong>Phone:</strong> {winner.phone}
                  </Text>
                  <Text>
                    <strong>Date:</strong>
                    {new Date(
                      String(winner.createdAt).split('T')[0]
                    ).toLocaleDateString('en-US', options)}{' '}
                  </Text>
                </Box>
              ) : (
                <Text>
                  No winner selected yet. Click "Select Winner" to choose a
                  random winner.
                </Text>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom={'1px solid'}
            borderColor={'primaryGreen.100'}
          >
            <Heading textAlign={'left'} fontSize={'20px'}>
              View Video
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Stack
              direction={'row'}
              alignItems={'center'}
              fontWeight={'600'}
              my={'20px'}
            >
              <iframe
                width="100%"
                height={500}
                title="Contest-Video"
                src={`${imgUrl}/${selectedVideo}`}
                allowFullScreen
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Winner Modal */}
      <Modal isOpen={isWinnerOpen} onClose={onWinnerClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom={'1px solid'}
            borderColor={'primaryGreen.100'}
          >
            <Heading textAlign={'left'} fontSize={'20px'}>
              Winner
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            {/* Display two fields for email and name */}
            <form onSubmit={handleSubmit}>
              <FormControl mb="15px" isRequired>
                <FormLabel color="primaryGreen.100">Name</FormLabel>
                <Input name="name" placeholder="Enter full name" size="md" />
              </FormControl>

              <FormControl mb="15px" isRequired>
                <FormLabel color="primaryGreen.100">Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  size="md"
                  name="email"
                  value={emailInput}
                  onChange={handleEmailChange}
                />

                {showSuggestions && filteredEmails.length > 0 && (
                  <Box border="1px solid #ccc" mt={1} borderRadius="md">
                    <List>
                      {filteredEmails.map((email, index) => (
                        <ListItem
                          key={index}
                          p={2}
                          _hover={{
                            backgroundColor: 'gray.100',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleSuggestionClick(email)}
                        >
                          {email}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </FormControl>

              <Button
                isLoading={isLoading}
                isDisabled={isLoading}
                type="submit"
                color="white"
                bg="primaryYellow.100"
                _hover={{ bg: 'primaryYellow.200' }}
                size={{ base: 'sm', md: 'md', xl: 'lg' }}
                width="full"
                transform={'Skew(-8deg)'}
              >
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
